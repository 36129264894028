
















import { Component, Vue } from "vue-property-decorator";
import CitasSemanaActual from "@/store/modules/Dashboards/CitasSemanaActual-module";
@Component({
  components: {}
})
export default class CitasPendietesVsRealizadas extends Vue {
  public created() {
    CitasSemanaActual.getPendientesVsRealizadas();
  }
  public get datos() {
    return CitasSemanaActual.CitasPendientesVsRealizadas;
  }
}
