import { store } from '@/store/store';
import { DashCitasPendientesrealizadasSemana } from '@/shared/dtos/DashCitasPendientesrealizadasSemana';
import {
    VuexModule,
    Mutation,
    getModule,
    Module,
    Action
} from 'vuex-module-decorators';
import { ssmHttpService } from '@/shared/services/http-service';
import { API } from '@/shared/api';

@Module({
    namespaced: true,
    name: 'DashCitasPendientesrealizadasSemanaModule',
    store,
    dynamic: true
})
class DashCitasPendientesrealizadasSemanaModule extends VuexModule {
    public CitasSemanaActuales: DashCitasPendientesrealizadasSemana = new DashCitasPendientesrealizadasSemana();
    public CitasPendientesVsRealizadas: DashCitasPendientesrealizadasSemana = new DashCitasPendientesrealizadasSemana();

    @Action({ commit: 'onGetCitasSemanaActuales' })
    public async getCitasSemanaActuales() {
        return await ssmHttpService.get(API.DashCitasSemanaActual, null, false);
    }
    @Action({ commit: 'onGetPendientesVsRealizadas' })
    public async getPendientesVsRealizadas() {
        return await ssmHttpService.get(API.DashCitasCitasRealizadasVsPendientes, null, false);
    }

    @Mutation
    public onGetCitasSemanaActuales(res: DashCitasPendientesrealizadasSemana) {
        this.CitasSemanaActuales = new DashCitasPendientesrealizadasSemana(res);
    }
    @Mutation
    public onGetPendientesVsRealizadas(res: DashCitasPendientesrealizadasSemana) {
        this.CitasPendientesVsRealizadas = new DashCitasPendientesrealizadasSemana(res);
    }

}
export default getModule(DashCitasPendientesrealizadasSemanaModule);